<template lang="">
  <div>
    <!-- Start Filter -->
    <b-card v-if="showFilter">
      <h1>Filter</h1>
      <b-row>
        <b-col cols="12">
          <form-v-select
            ref="region"
            v-model="region"
            dataurl="region"
            col-label="2"
            label="Region"
            placeholder="Semua Region"
            :item_text="'name'"
          />
        </b-col>
        <b-col cols="12">
          <b-form-group
            label-cols-md="2"
            label="Tanggal"
          >
            <b-input-group>
              <flat-pickr
                v-model="date_start"
                class="form-control"
                placeholder="Tanggal Awal"
              />
              <b-input-group-append is-text>
                To
              </b-input-group-append>
              <flat-pickr
                v-model="date_end"
                class="form-control"
                placeholder="Tanggal Akhir"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- <b-col cols="12">
          <b-form-group
            label-cols-md="2"
            label="Status"
          >
            <b-form-select
              v-model="status"
              :options="[
                { value: null, text: 'Semua Status' },
                { value:'canceled',text:'Dibatalkan'},
                { value:'viewed',text:'SPB Ditinjau'},
                { value:'created',text:'Pembuatan SPB'},
                { value:'applied',text:'Pengajuan SPB'},
                { value:'delivered',text:'Pengiriman SPB Ke Vendor'},
                { value:'prepared',text:'Penyiapan Unit Oleh Vendor'},
                { value:'distributed',text:'Pengiriman Unit dari Vendor'},
                { value:'received',text:'SPB Diterima'},
                { value:'approved',text:'SPB Disetujui'},
                { value:'returned',text:'SPB Direvisi'},

              ]"
            />
          </b-form-group>
        </b-col> -->
        <b-col cols="12">
          <form-v-select
            ref="vendor"
            v-model="vendor"
            dataurl="vendor"
            col-label="2"
            label="Vendor"
            placeholder="Semua Vendor"
            :item_text="'name'"
          />
        </b-col>
        <b-col cols="12">
          <b-form-group
            label-cols-md="2"
            label=""
          >
            <b-button
              class="mr-1"
              size="sm"
              variant="primary"
              type="button"
              @click="show"
            >Show</b-button>
            <b-button
              class="mr-1"
              size="sm"
              variant="outline-secondary"
              type="button"
              @click="reset"
            >Reset</b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <!-- End Of Filter -->
    <b-card v-if="renderComp">
      <basetable
        ref="basetable"
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :filter="filter"
        :custom_filter="custom_filter"
        :tombol_hide="true"
        :is-detail="isDetail"
        :is-add="true"
        :is-edit="false"
        :is-delete="false"
      >
        <template v-slot:buttonaddon>
          <b-button
            class="float-right"
            variant="info"
            @click="showFilter =!showFilter"
          >
            <feather-icon
              icon="FilterIcon"
            />
            Filter
          </b-button>
        </template>
        <template #cell(number)="data">
          {{ data.item.number }}
          <br>
          <small class="text-muted">{{ data.item.date }}</small>
        </template>
        <template #cell(status)="data">
        <center><span class="badge rounded-pill bg-info" v-if="data.item.status == 'created'">Pembuatan SPB</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'prepared'">Penyiapan Unit Oleh Vendor</span></center>
        <center><span class="badge rounded-pill bg-info" v-if="data.item.status == 'applied'">Pengajuan SPB</span></center>
        <center><span class="badge rounded-pill bg-info" v-if="data.item.status == 'delivered'">Pengiriman SPB Ke Vendor</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'viewed'">SPB Ditinjau</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'approved'">SPB Ditinjau</span></center>
        <center><span class="badge rounded-pill bg-warning" v-if="data.item.status == 'canceled'">Dibatalkan</span></center>
        <center><span class="badge rounded-pill bg-warning" v-if="data.item.status == 'returned'">SPB Direvisi</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'received'">Pengiriman Unit</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'distributed'">Pengiriman Unit dari Vendor</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'request_rikmatek'">Pengajuan Rikmatek</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'checked'">Sudah Diperiksa</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'send_letter_distribute'">Sudah Pemeriksaan</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'bapp_published'">BAPP Dipublish</span></center>
        <center><span class="badge rounded-pill bg-success" v-if="data.item.status == 'bapp_requested'">BAPP Direquest</span></center>
        </template>
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV4.vue'
import { BCard,BButton,BRow,BCol,BFormSelect, BFormGroup, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import FormSelectPopup from '@/views/base/form/FormSelectPopup.vue'
import FormDateRangeMonth from '@/views/base/form/FormDateRangeMonth.vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import flatPickr from 'vue-flatpickr-component'
// import vSelect from 'vue-select'

export default {
  components: {
    basetable,
    BCard,
    BButton,
    FormVSelect,
    FormSelectPopup,
    BRow,
    BCol,
    flatPickr,
    FormDateRangeMonth,
    BFormSelect, 
    BFormGroup, 
    BInputGroup, 
    BInputGroupAppend
  },
  data() {
    return {
      dataurl:"/spb?status=prepared",
      baseroute:"persiapan-unit",
      title:"Daftar Persiapan Unit",
      fields: [
        { key: 'region.name', label: 'Region', sortable: true },
        { key: 'number', label: 'No SPB', sortable: true },
        { key: 'contract_subject', label: 'Judul Kontrak', sortable: true },
        { key: 'contract_number', label: 'No Kontrak', sortable: true },
        { key: 'vendor.name', label: 'Vendor'},
        { key: 'status', label: 'Status', sortable: true },
      ],
      showFilter : false,
      custom_filter : true,
      isDetail : true,
      renderComp : true,
      isDelete : false,
      isEdit : false,
      filter:[],
      region:null,
      status:null,
      vendor:null,
      date_start:"",
      date_end:""
    }
  },
  methods:{
    show(){
      this.filter['filter[status]']=this.status
      this.filter['filter[branch_id]']=this.region?this.region.id:null
      this.filter['filter[vendor_id]']=this.vendor?this.vendor.id:null
      if(this.date_start != "" && this.date_end != "")
        this.filter['between[date]']=this.date_start+","+this.date_end
      this.$refs.basetable.fetchData()
    },
    reset(){
      this.region = null
      this.status = null
      this.vendor = null
      this.date_start = ""
      this.date_end = ""
      this.show()
    }
  }
}
</script>
<style lang="">

</style>
